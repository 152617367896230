const domainList = [
    "Web Development",
    "App Development",
    "Machine Learning",
    "Cloud Computing",
    "Java",
    "Networking",
    "Internet of Things",
    "Embedded Systems",
    "AR/VR",
    "Marketing",
    "Graphics Designer",
    "Video Editing",
    "Content Writer",
    "Photography",
];
export default domainList;
