import pic1 from "../images/image/Carousal5.jpg";
import pic2 from "../images/image/Carousal3.jpg";
import pic3 from "../images/image/Carousal4.jpg";
import pic4 from "../images/image/Carousal2.jpg";
import pic5 from "../images/image/Carousal6.jpg";
import pic6 from "../images/image/Carousal7.jpg";
import pic7 from "../images/image/Carousal8.jpg";
import pic8 from "../images/image/Carousal9.jpg";
import pic9 from "../images/image/Carousal10.jpg";
import pic10 from "../images/image/Carousal11.jpg";

const Images = [
    {
        id: 2,
        img: pic1,
    },
    {
        id: 3,
        img: pic2,
    },
    {
        id: 4,
        img: pic3,
    },
    {
        id: 5,
        img: pic4,
    },
    {
        id: 6,
        img: pic5,
    },
    {
        id: 7,
        img: pic6,
    },
    {
        id: 8,
        img: pic7,
    },
    {
        id: 9,
        img: pic8,
    },
    {
        id: 10,
        img: pic9,
    },
    {
        id: 11,
        img: pic10,
    },
];

export default Images;
