export const list = [
    {
        title: "Home",
        url: "/",
        cName: "nav-links",
    },
    {
        title: "Team",
        url: "/team",
        cName: "nav-links",
    },
    {
        title: "Course",
        url: "/course   ",
        cName: "nav-links",
    },
    {
        title: "Login",
        url: "/dashboard/login",
        cName: "login-btn",
    },
];
